import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Link from "@src/deprecatedDesignSystem/components/Link";
import Text from "@ui/text";
import { StyleSheet } from "aphrodite";
import { FC, ReactNode, useMemo } from "react";

export type ContextMenuItemProps = {
  icon?: DeprecatedIconType;
  iconProps?: Partial<React.ComponentProps<typeof DeprecatedIcon>>;
  label: string;
  labelStyle?: React.CSSProperties;
  align?: "left" | "right";
  disabled?: boolean;
  submenu?: ReactNode;
  onSelect?: () => void;
  downloadUrl?: string;
  dataTestId?: string;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
};

const ContextMenuItem: FC<ContextMenuItemProps> = ({
  icon,
  iconProps,
  label,
  labelStyle,
  align = "left",
  disabled,
  onSelect,
  downloadUrl,
  dataTestId,
  style,
  hoverStyle,
}) => {
  const item = useMemo(() => {
    return (
      <AutoLayout
        style={{
          flexGrow: 1,
          maxWidth: 254,
          maxHeight: 32,
          paddingRight: 16,
          cursor: "pointer",
          userSelect: "none",
          borderRadius: 8,
          ...style,
        }}
        hoverStyle={{
          backgroundColor: deprecatedColors.primaryHover,
          ...hoverStyle,
        }}
        direction="horizontal"
        alignmentVertical="center"
        alignmentHorizontal={align}
        alignSelf={"stretch"}
        paddingVertical={4}
        styleDeclaration={[disabled && styles.disabled]}
        onClick={(e) => {
          if (onSelect && !disabled) {
            e.stopPropagation();
            onSelect();
          }
        }}
        data-testid={dataTestId}
      >
        {icon && (
          <DeprecatedIcon
            type={icon}
            color={deprecatedTones.gray10}
            style={{ marginRight: 8 }}
            {...iconProps}
          />
        )}
        <Text
          type="P2"
          fontWeight={"Medium"}
          style={{
            marginLeft: !icon ? 8 : undefined,
            ...labelStyle,
          }}
        >
          {label}
        </Text>
      </AutoLayout>
    );
  }, [
    style,
    hoverStyle,
    align,
    disabled,
    onSelect,
    dataTestId,
    icon,
    iconProps,
    label,
    labelStyle,
  ]);
  return downloadUrl ? (
    <Link target={"_blank"} externalUrl={downloadUrl}>
      {item}
    </Link>
  ) : (
    item
  );
};

export default ContextMenuItem;

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
    backgroundColor: deprecatedColors.disabled,
  },
});
